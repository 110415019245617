<template>
  <div id="weather-page">
    <TitleHelper :title="'Your Local Weather'"></TitleHelper>
    <div class="display">
      <div v-if="errorValue" style="margin-bottom: 10px;">
          {{errorValue}}
      </div>
      <div style = "display: flex; flex-wrap: wrap;">
        <div class='location' :class="{'appear': processedPlaceName}">
            {{processedPlaceName}}
        </div>
        <div class='time' :class="{'appear': processedUpdateTime}">
            {{processedUpdateTime}}
        </div>
      </div>
    <br />
      <div style="display: flex; width:100%; flex-wrap: wrap; min-height: calc(100vh - 82px); overflow: scroll;">
        <div v-for="(d,n) in data" :key="'date-'+n" :id="'date-'+n" class="date-object" 
        @click="enlarge('date-'+n, d)"
        :class="{
            ['uvindex' + d.uvIndex]: true,
            'stat': notSafari,
            'appear': d.display
        }">
            <div>{{dateOrg(d.time)}}</div>
            <div style="font-size: 18px;">{{timeOrg(d.time)}}</div>
            <div class="symbol">
                <i class="wi" :class="weatherLookup[d.significantWeatherCode]['icon']"></i>
            </div>
            <div class="temp">{{d.screenTemperature.toFixed(0)}}&#730;C</div>
            <div class="rain" style="display: flex;">
                <div style="font-size: 15px; margin-right: 3px; width:40%;">
                    <i class="wi wi-umbrella"></i>
                </div>
                <div style="width: 60%;">
                    <div>{{d.probOfPrecipitation}}%</div>
                    <div v-if="d.hasOwnProperty('totalPrecipAmount')">{{d.totalPrecipAmount.toFixed(1)}}mm</div>
                </div>
            </div>
        </div>
      </div>
      <div class="sheet hidden" @click="unenlarge()">
      </div>
      <div class="date-object hidden" id="selector" style="position: absolute; opacity: 1;">
        <span v-if="this.selectorData" style="position: relative;">
            <div>{{dateOrg(this.selectorData.time)}}</div>
            <div style="font-size: 18px;">{{timeOrg(this.selectorData.time)}}</div>
            <div class="symbol">
                <i class="wi" :class="weatherLookup[this.selectorData.significantWeatherCode]['icon']"></i>
            </div>
            <div class="temp">{{this.selectorData.screenTemperature.toFixed(0)}}&#730;C</div>
            <div class="selector-rain" style="display: flex;">
                <div id="selector-rain-col-symbol" style="font-size: 15px; margin-right: 3px; width:40%;">
                    <i class="wi wi-umbrella"></i>
                </div>
                <div style="width: 60%;" id="selector-rain-col-data">
                    <div id="selector-rain-pc">{{this.selectorData.probOfPrecipitation}}%</div>
                    <div v-if="this.selectorData.hasOwnProperty('totalPrecipAmount')" id="selector-rain-amt">{{this.selectorData.totalPrecipAmount.toFixed(2)}}mm</div>
                </div>
            </div>
            <div id="selector-thermo" style="display: flex; margin-top: 5px; margin-left: 10%; margin-right: 10%; background-color: #22d; border-radius: 5px; padding: 5px;" class="hidden">
                <div style="font-size: 20px; margin-right: 3px; width: 15%;">
                    <i class="wi wi-thermometer"></i>
                </div>
                <div style="width: 85%;">
                    <div v-if="this.selectorData.minScreenAirTemp" id="selector-thermo-min">Minimum air temperature: {{this.selectorData.minScreenAirTemp.toFixed(2)}}&#730;C</div>
                    <div v-if="this.selectorData.maxScreenAirTemp" id="selector-thermo-max">Maximum air temperature: {{this.selectorData.maxScreenAirTemp.toFixed(2)}}&#730;C</div>
                    <div id="selector-thermo-feel"><b>Feels Like: {{this.selectorData.feelsLikeTemperature.toFixed(2)}}&#730;C</b></div>
                </div>
            </div>
            <div id="selector-wind" style="display: flex; margin-top: 5px; margin-left: 10%; margin-right: 10%; background-color: #22d; border-radius: 5px; padding: 5px;" class="hidden">
                <div style="font-size: 20px; margin-right: 3px; width: 15%;">
                    <i class="wi wi-wind" :class="'from-'+this.selectorData.windDirectionFrom10m.toFixed(0)+'-deg'"></i>
                </div>
                <div style="width: 85%;">
                    <div id="selector-thermo-min">Wind speed: {{this.selectorData.windSpeed10m.toFixed(2)}}m/s</div>
                    <div v-if="this.selectorData.max10mWindGust" id="selector-thermo-max">Wind gusts: {{this.selectorData.max10mWindGust.toFixed(2)}}m/s</div>
                </div>
            </div>
            <div id="selector-humid" style="display: flex; margin-top: 5px; margin-left: 10%; margin-right: 10%; background-color: #22d; border-radius: 5px; padding: 5px;" class="hidden">
                <div style="font-size: 20px; margin-right: 3px; width: 15%;">
                    <i class="wi wi-humidity"></i>
                </div>
                <div style="width: 85%;">
                    <div id="selector-humidity">Relative humidity: {{this.selectorData.screenRelativeHumidity.toFixed(1)}}%</div>
                </div>
            </div>
            <div id="selector-uv" style="display: flex; margin-top: 5px; margin-left: 10%; margin-right: 10%; background-color: #22d; border-radius: 5px; padding: 5px;" class="hidden">
                <div style="font-size: 20px; margin-right: 3px; width: 15%;">
                    <i class="wi wi-hot"></i>
                </div>
                <div style="width: 85%;">
                    <div id="selector-uv-index">UV index: {{this.selectorData.uvIndex}}</div>
                </div>
            </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "Weather",
  beforeCreate: function() {
    document.body.className = 'weather';
  },
  data() {
    return {
        values: config,
        notSafari: true,
        errorValue: null,
        latitude: null,
        longitude: null,
        location: null,
        updateTime: null,
        selectorData: null,

        weatherLookup: {
            0: {'description': 'Clear night', 'icon': 'wi-night-clear'},
            1: {'description': 'Sunny day', 'icon': 'wi-day-sunny'},
            2: {'description': 'Partly cloudy (night)', 'icon': 'wi-night-alt-cloudy'},
            3: {'description': 'Partly cloudy (day)', 'icon': 'wi-day-cloudy'},
            4: {'description': 'Not used', 'icon': ''},
            5: {'description': 'Mist', 'icon': 'wi-day-fog'},
            6: {'description': 'Fog', 'icon': 'wi-fog'},
            7: {'description': 'Cloudy', 'icon': 'wi-day-sunny-overcast'},
            8: {'description': 'Overcast', 'icon': 'wi-cloudy'},
            9: {'description': 'Light rain shower (night)', 'icon': 'wi-night-alt-showers'},
            10: {'description': 'Light rain shower (day)', 'icon': 'wi-day-showers'},
            11: {'description': 'Drizzle', 'icon': 'wi-showers'},
            12: {'description': 'Light rain', 'icon': 'wi-showers'},
            13: {'description': 'Heavy rain shower (night)', 'icon': 'wi-night-alt-rain'},
            14: {'description': 'Heavy rain shower (day)', 'icon': 'wi-day-rain'},
            15: {'description': 'Heavy rain', 'icon': 'wi-rain'},
            16: {'description': 'Sleet shower (night)', 'icon': 'wi-night-alt-sleet'},
            17: {'description': 'Sleet shower (day)', 'icon': 'wi-day-sleet'},
            18: {'description': 'Sleet', 'icon': 'wi-sleet'},
            19: {'description': 'Hail shower (night)', 'icon': 'wi-night-alt-hail'},
            20: {'description': 'Hail shower (day)', 'icon': 'wi-day-hail'},
            21: {'description': 'Hail', 'icon': 'wi-hail'},
            22: {'description': 'Light snow shower (night)', 'icon': 'wi-night-alt-snow'},
            23: {'description': 'Light snow shower (day)', 'icon': 'wi-day-snow'},
            24: {'description': 'Light snow', 'icon': 'wi-snow'},
            25: {'description': 'Heavy snow shower (night)', 'icon': 'wi-night-alt-snow'},
            26: {'description': 'Heavy snow shower (day)', 'icon': 'wi-day-snow'},
            27: {'description': 'Heavy snow', 'icon': 'wi-snow'},
            28: {'description': 'Thunder shower (night)', 'icon': 'wi-night-alt-thunderstorm'},
            29: {'description': 'Thunder shower (day)', 'icon': 'wi-day-thunderstorm'},
            30: {'description': 'Thunder', 'icon': 'wi-thunderstorm'},
        },
        data : null,
    };
  },
  mounted() {
    if (navigator.vendor.indexOf('Apple') != -1) {
        this.notSafari = false;
    }
    navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
  },
  components: {
    TitleHelper,
  },
  methods: {
    geoSuccess: function(loc) {
      this.latitude = (loc.coords.latitude);
      this.longitude = (loc.coords.longitude);
      this.getWeather();
    },
    geoError: function(err) {
      this.errorValue = ("Location not available, defaulting to central London: " + err);
      this.latitude = 51.500699;
      this.longitude = -0.124598;
      this.getWeather();
    },
    getWeather: function() {
        if (this.longitude && this.latitude) {
            var url = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/weather?lat="+this.latitude+"&long="+this.longitude;
            var config = {};
            axios
                .get(url,config)
                .then(response => {
                    this.location = response.data.features[0].properties.location.name;
                    this.updateTime = response.data.features[0].properties.modelRunDate;

                    let temp = response.data.features[0].properties.timeSeries;
                    for (var n of temp) {
                        n.display = false;
                    }
                    this.data = temp;
                })
                .catch(error => {
                    this.errorValue = error;
                })
        } else {
            this.errorValue = "You will need to permit location services."
        }
    },
    dateTimeOrg: function(dateObject) {
        let n = new Date(dateObject);
        let day = n.getDate();
        let month = (parseInt(n.getMonth())+1);
        let hour = n.getHours();
        let minute = n.getMinutes();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        return day + '/' + month + '/' + n.getFullYear() + " " + hour + ":" + minute;
    },
    dateOrg: function(dateObject) {
        let n = new Date(dateObject);
        let day = n.getDate();
        let month = (parseInt(n.getMonth())+1);
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return day + '/' + month + '/' + n.getFullYear();
    },
    timeOrg: function(dateObject) {
        let n = new Date(dateObject);
        let hour = n.getHours(); 
        let minute = n.getMinutes();
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minute < 10) {
            minute = '0' + minute;
        }
        return hour + ":" + minute;
    },
    enlarge: function(id, data) {
        this.selectorData = data;
        var el = document.getElementById(id);
        var offset = document.getElementById(id).parentNode.scrollTop;

        var selector = document.getElementById('selector');
        selector.style.top = el.offsetTop-3-offset + 'px';
        selector.style.left = el.offsetLeft-3 + 'px';
        selector.style.width = el.offsetWidth-10 + 'px';
        selector.style.height = el.offsetHeight-10 + 'px';
        selector.classList.remove("hidden");

        var sheet = document.getElementsByClassName('sheet')[0];
        sheet.classList.remove("hidden");
        setTimeout(() => {
            selector.style.top = 30+'px';
            selector.style.left = 'calc(20vw)';
            selector.style.width = 60+'%';
            selector.style.height = 80+'%';
            var el = document.getElementById('selector-rain-col-symbol');
            el.style.transition = "all 0.5s ease-in-out";
            el.style.width = 15+'%';
            el.style.fontSize = 20+'px';
            el = document.getElementById('selector-rain-col-data');
            el.style.transition = "all 0.5s ease-in-out";
            el.style.width = 85+'%';
            el = document.getElementsByClassName('selector-rain')[0];
            el.style.transition = "all 0.5s ease-in-out";
            el.style.marginTop = 5+'px';
            el.style.marginLeft = 10+'%';
            el.style.marginRight = 10+'%';
            el.style.borderRadius = '5px';
            el.style.backgroundColor = '#22d';
            el.style.padding = '3px';
        }, 100);
        setTimeout(() => {
            var el = document.getElementById('selector-rain-pc');
            el.innerHTML += ' chance of rain'
            el = document.getElementById('selector-rain-amt');
            el.innerHTML += ' precipitation'
            el = document.getElementById('selector-thermo');
            el.classList.remove("hidden");
            el = document.getElementById('selector-uv');
            if (this.selectorData.uvIndex <= 2) {
                el.style.backgroundColor = 'green';
            } else if (this.selectorData.uvIndex <= 5) {
                el.style.backgroundColor = 'yellow';
                el.style.color = 'black';
            } else if (this.selectorData.uvIndex <= 7) {
                el.style.backgroundColor = 'orange';
                el.style.color = 'black';
            } else if (this.selectorData.uvIndex <= 10) {
                el.style.backgroundColor = 'red';
                el.style.color = 'black';
            } else {
                el.style.backgroundColor = 'violet';
                el.style.color = 'black';
            }
        }, 800)
    },
    unenlarge: function() {
        var selector = document.getElementById('selector');
        selector.style.minHeight = null;
        selector.classList.add("hidden");
        var sheet = document.getElementsByClassName('sheet')[0];
        sheet.classList.add("hidden");
        this.selectorData = null;
        var el = document.getElementById('selector-thermo');
        el.classList.add("hidden");
    }
  },
  computed: {
        processedPlaceName: function() {
            if (this.location) {
                return this.location.split(',')[0];
            } else {
                return null;
            }
        },
        processedUpdateTime: function() {
            if (this.updateTime) {
                return this.dateTimeOrg(this.updateTime);
            } else {
                return null;
            }
        }
  },
  watch: {
    data: function() {
        for(let i = 0; i<this.data.length; i++){
            let delay = (i * 200) + 10;

            setTimeout(function(){
                this.data[i].display = true;
            }.bind(this), delay);
        }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "./css/weather-icons.min.css";
@import "./css/weather-icons-wind.min.css";

#weather-page {
  padding: 8px;
  min-height: calc(100vh - 16px);
  background-color: black;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: yellow;
}

div.location, div.time {
    background-color: rgb(39, 39, 146);
    color: white;
    border-radius: 10px;
    height: 50px;
    line-height: 50px;
    display: block;
    width: 48%;
    margin: auto;
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

div.location.appear, div.time.appear {
    opacity: 1;
}

.date-object {
    width: 55px;
    position:relative;
    top: 50px;
    font-size: 10px;
    padding: 5px;
    opacity: 0;
    background-color: #44f;
    border-radius: 5px;
    margin: 3px;
    height: 150px;
}

.date-object.stat {
    transition: opacity 4s ease-in-out, top 4s ease-in-out;
}

.date-object.uvindex0 {
    background-color: #006;
    color: #770;
}

.date-object.uvindex1 {
    background-color: #119;
    color: #880;
}

.date-object.uvindex2 {
    background-color: #22a;
    color: #990;
}

.date-object.uvindex3 {
    background-color: #33b;
    color: #aa0;
}

.date-object.uvindex4 {
    background-color: #33c;
    color: #bb0;
}

.date-object.uvindex5 {
    background-color: #33d;
    color: #dd0;
}

.date-object.uvindex6 {
    background-color: #44e;
    color: #ee0;
}

.date-object.uvindex7 {
    background-color: #44f;
    color: #ff0;
}


.date-object.appear {
    opacity: 1;
    top:0px;
}

.date-object.hidden, div.hidden {
    display: none;
}

.symbol {
    font-size: 40px;
    margin-top: 2px;
}

.temp {
    font-size: 20px;
}

#selector {
    z-index: 2;
    transition: top 1s ease-in-out, left 1s ease-in-out, width 1s ease-in-out, height 1s ease-in-out;
    overflow: hidden;
}

div.sheet {
    background-color: black;
    opacity: 0.6;
    position: absolute;
    top:0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

div.sheet.hidden {
    display: none;
}

</style>

